import { useState, FormEvent } from "react";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Box } from "@twilio-paste/core/box";
import { TextArea } from "@twilio-paste/core/textarea";
import { Button } from "@twilio-paste/core/button";
import { Checkbox } from "@twilio-paste/core/checkbox";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";
import { Select, Option } from "@twilio-paste/core/select";
import { Flex } from "@twilio-paste/core/flex";
import { ArrowBackIcon } from "@twilio-paste/icons/esm/ArrowBackIcon";

import { sessionDataHandler } from "../sessionDataHandler";
import { addNotification, changeEngagementPhase, updatePreEngagementData } from "../store/actions/genericActions";
import { initSession } from "../store/actions/initActions";
import { AppState, EngagementPhase } from "../store/definitions";
import { Header } from "./Header";
import { notifications } from "../notifications";
import { NotificationBar } from "./NotificationBar";
import { introStyles, fieldStyles, titleStyles, formStyles } from "./styles/PreEngagementFormPhase.styles";

const ContactMethods = {
    EMAIL: "Email",
    PHONE: "Phone"
};


export const PreEngagementFormPhase = () => {
    const { firstName, lastName, companyName, email, phoneNumber, zipcode, interest, query, preferredContactMethod, acceptedTerms } = useSelector((state: AppState) => state.session.preEngagementData) || {};
    const dispatch = useDispatch();

    // State to handle visibility of the full form and interest selection
    const [showFullForm, setShowFullForm] = useState(false);

    const handleInterestSelect = (selectedInterest: string) => {
        dispatch(updatePreEngagementData({ interest: selectedInterest as "" | "Machine Sales" | "Rental Equipment" | "Parts" | "Service" | "Something Else" }));
        setShowFullForm(true); // Show the full form after interest selection
    };

    const handleBackClick = () => {
        setShowFullForm(false); // Go back to interest selection
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        // Basic validation regex
        const phoneRegex = /^\d{10}$/; // Exactly 10 digits
        const zipRegex = /^\d{5}$/; // Exactly 5 digits
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation

        // Trim spaces from inputs
        const trimmedPhone = (phoneNumber || '').replace(/\s+/g, '');
        const trimmedZip = (zipcode || '').replace(/\s+/g, '');
        const trimmedEmail = (email || '').trim();

        if (!phoneRegex.test(trimmedPhone)) {
            dispatch(addNotification(notifications.failedValidationNotification("Phone number must be exactly 10 digits.")));
            return;
        }

        if (!zipRegex.test(trimmedZip)) {
            dispatch(addNotification(notifications.failedValidationNotification("Zip code must be exactly 5 digits.")));
            return;
        }

        if (!emailRegex.test(trimmedEmail)) {
            dispatch(addNotification(notifications.failedValidationNotification("Please enter a valid email address.")));
            return;
        }

        if (!acceptedTerms) {
            dispatch(addNotification(notifications.termsNotAcceptedNotification()));
            return;
        }

        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        try {
            const data = await sessionDataHandler.fetchAndStoreNewSession({
                formData: {
                    firstName,
                    lastName,
                    companyName,
                    email,
                    phoneNumber,
                    zipcode,
                    interest,
                    query,
                    preferredContactMethod
                }
            });
            dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
        } catch (err) {
            console.log("PreEngagementFormPhase");
            console.log(err);
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    return (
        <>
            <Header />
            <NotificationBar />
            <Box as="form" data-test="pre-engagement-chat-form" onSubmit={handleSubmit} {...formStyles}>
                <Text {...titleStyles} as="h3">
                    Welcome to Louisiana Cat!
                </Text>
                {!showFullForm && (
                    <>
                        <Text {...introStyles} as="p">
                            Please select an interest to get started.
                        </Text>
                        {/* Interest Selection Section */}
                        <Flex wrap hAlignContent="center" marginBottom="space60" vertical>
                            {["Machine Sales", "Rental Equipment", "Parts", "Service", "Something Else"].map((item) => (
                                <Box key={item} margin="space30">
                                    <Button
                                        key={item}
                                        variant="secondary"
                                        onClick={() => handleInterestSelect(item)}
                                        data-test={`interest-bubble-${item.toLowerCase().replace(" ", "-")}`}
                                        style={{ borderRadius: "20px", padding: "10px 20px", margin: "5px" }}
                                    >
                                        {item}
                                    </Button>
                                </Box>
                            ))}
                        </Flex>
                    </>
                )}

                {showFullForm && (
                    <>
                        {/* Back Button and Interest Display */}
                        <Flex display="flex" vAlignContent="center" marginBottom="space50">
                            <Button variant="link" onClick={handleBackClick} style={{ padding: 0 }}>
                                <ArrowBackIcon decorative={false} title="Back" />
                            </Button>
                            <Text as="span" fontWeight="fontWeightBold" marginLeft="space20">
                                Selected Interest: {interest}
                            </Text>
                        </Flex>

                        <Text {...introStyles} as="p">
                            Please provide some more information to continue.
                        </Text>

                        {/* Remaining Form Fields */}
                        <Box {...fieldStyles}>
                            <Label htmlFor="firstName">First Name</Label>
                            <Input
                                type="text"
                                placeholder="Enter your first name"
                                name="firstName"
                                data-test="pre-engagement-chat-form-first-name-input"
                                value={firstName}
                                onChange={(e) => dispatch(updatePreEngagementData({ firstName: e.target.value }))}
                                required
                            />
                        </Box>

                        <Box {...fieldStyles}>
                            <Label htmlFor="lastName">Last Name</Label>
                            <Input
                                type="text"
                                placeholder="Enter your last name"
                                name="lastName"
                                data-test="pre-engagement-chat-form-last-name-input"
                                value={lastName}
                                onChange={(e) => dispatch(updatePreEngagementData({ lastName: e.target.value }))}
                                required
                            />
                        </Box>

                        <Box {...fieldStyles}>
                            <Label htmlFor="companyName">Company Name</Label>
                            <Input
                                type="text"
                                placeholder="Enter your company name"
                                name="companyName"
                                data-test="pre-engagement-chat-form-company-name-input"
                                value={companyName}
                                onChange={(e) => dispatch(updatePreEngagementData({ companyName: e.target.value }))}
                            />
                        </Box>

                        <Box {...fieldStyles}>
                            <Label htmlFor="email">Email Address</Label>
                            <Input
                                type="email"
                                placeholder="Enter your email address"
                                name="email"
                                data-test="pre-engagement-chat-form-email-input"
                                value={email}
                                onChange={(e) => dispatch(updatePreEngagementData({ email: e.target.value }))}
                                required
                            />
                        </Box>

                        <Box {...fieldStyles}>
                            <Label htmlFor="phoneNumber">Phone Number</Label>
                            <Input
                                type="tel"
                                placeholder="Enter your phone number"
                                name="phoneNumber"
                                data-test="pre-engagement-chat-form-phone-input"
                                value={phoneNumber}
                                onChange={(e) => dispatch(updatePreEngagementData({ phoneNumber: e.target.value }))}
                                required
                            />
                        </Box>

                        <Box {...fieldStyles}>
                            <Label htmlFor="zipcode">Zipcode</Label>
                            <Input
                                type="text"
                                placeholder="Enter your zipcode"
                                name="zipcode"
                                data-test="pre-engagement-chat-form-zipcode-input"
                                value={zipcode}
                                onChange={(e) => dispatch(updatePreEngagementData({ zipcode: e.target.value }))}
                                required
                            />
                        </Box>


                        <Box {...fieldStyles}>
                            <Label htmlFor="preferredContactMethod">Preferred Method of Contact</Label>
                            <Select
                                id="preferredContactMethod"
                                name="preferredContactMethod"
                                value={preferredContactMethod}
                                onChange={(e) => dispatch(updatePreEngagementData({ preferredContactMethod: e.target.value }))}
                                data-test="pre-engagement-chat-form-preferred-contact-method-select"
                            >
                                <Option value="">Select a contact method</Option>
                                    {(Object.keys(ContactMethods) as Array<keyof typeof ContactMethods>).map((key) => (
                                        <Option key={key} value={ContactMethods[key]}>
                                            {ContactMethods[key]}
                                        </Option>
                                    ))}
                            </Select>
                        </Box>

                        <Box {...fieldStyles}>
                            <Label htmlFor="query">Additional Details</Label>
                            <TextArea
                                placeholder="Ask a question"
                                name="query"
                                data-test="pre-engagement-chat-form-query-textarea"
                                value={query}
                                onChange={(e) => dispatch(updatePreEngagementData({ query: e.target.value }))}
                                onKeyPress={handleKeyPress}
                            />
                        </Box>

                        <Box {...fieldStyles}>
                            <Checkbox
                                checked={acceptedTerms}
                                onChange={(e) => dispatch(updatePreEngagementData({ acceptedTerms: e.target.checked }))}
                                name="terms"
                                data-testid="pre-engagement-chat-form-terms-checkbox"
                                id="terms-checkbox"
                            >
                                I agree to the{' '}
                                <a 
                                    href="https://www.louisianacat.com/privacy-policy/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: '#0066cc', textDecoration: 'underline' }}
                                >
                                    terms and conditions
                                </a>
                            </Checkbox>
                        </Box>

                        <Button variant="primary" type="submit" data-test="pre-engagement-start-chat-button">
                            Start Chat
                        </Button>
                    </>
                )}
            </Box>
        </>
    );
};
