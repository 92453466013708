import { Client, Conversation, Participant } from "@twilio/conversations";
import { Dispatch } from "redux";
import log from "loglevel";

import { initMessagesListener } from "./listeners/messagesListener";
import { initParticipantsListener } from "./listeners/participantsListener";
import { initConversationListener } from "./listeners/conversationListener";
import { ConfigState, EngagementPhase } from "../definitions";
import { initClientListeners } from "./listeners/clientListener";
import { notifications } from "../../notifications";
import { ACTION_START_SESSION, ACTION_LOAD_CONFIG } from "./actionTypes";
import { addNotification, changeEngagementPhase } from "./genericActions";
import { MESSAGES_LOAD_COUNT } from "../../constants";

export function initConfig(config: ConfigState) {
    return {
        type: ACTION_LOAD_CONFIG,
        payload: config
    };
}

export function initSession({ token, conversationSid }: { token: string; conversationSid: string }) {
    return async (dispatch: Dispatch) => {
        let conversationsClient: Client;
        let conversation: Conversation | undefined;
        let participants: Participant[];
        let users;
        let messages;

        try {
            conversationsClient = await Client.create(token);

            // Retry logic for fetching conversation
            const MAX_RETRIES = 3;
            let retries = 0;
            let lastError;

            while (retries < MAX_RETRIES) {
                try {
                    log.info(`Attempt ${retries + 1}: Fetching conversation for sid = ${conversationSid}`);
                    conversation = await conversationsClient.getConversationBySid(conversationSid);
                    break; // If successful, exit the loop
                } catch (e) {
                    lastError = e;
                    log.warn(`Attempt ${retries + 1} failed:`, e);
                    retries += 1;
                    if (retries < MAX_RETRIES) {
                        // Wait for a second before retrying
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    }
                }
            }

            if (!conversation) {
                log.error("Failed to fetch conversation after maximum retries", lastError);
                dispatch(addNotification(notifications.failedToInitSessionNotification("Couldn't load conversation after multiple attempts")));
                dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
                return;
            }

            participants = await conversation.getParticipants();
            users = await Promise.all(participants.map(async (p: Participant) => p.getUser()));
            messages = (await conversation.getMessages(MESSAGES_LOAD_COUNT)).items;

            // Sending the first message so that studio flow is triggered as soon as pre engagement form is submitted.
            if (messages.length === 0) {
                const currentConversation = conversation;  // Capture the current value of conversation
                setTimeout(async function() {
                    try {
                        let preparedMessage = currentConversation.prepareMessage();
                        preparedMessage = preparedMessage.setBody("");
                        await preparedMessage.build().send();
                    } catch (error) {
                        log.error("Error sending initial message:", error);
                    }
                }, 500);
            }

            dispatch({
                type: ACTION_START_SESSION,
                payload: {
                    token,
                    conversationSid,
                    conversationsClient,
                    conversation,
                    users,
                    participants,
                    messages,
                    conversationState: conversation.state?.current,
                    currentPhase: EngagementPhase.MessagingCanvas
                }
            });

            initClientListeners(conversationsClient, dispatch);
            initConversationListener(conversation, dispatch);
            initMessagesListener(conversation, dispatch);
            initParticipantsListener(conversation, dispatch);
        } catch (e) {
            log.error("Something went wrong when initializing session", e);
            throw e;
        }
    };
}